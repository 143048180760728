<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Add target file
          </v-card-title>
          <v-col
            cols="12"
            sm="6"
          >
            <v-form v-model="valid">
              <v-text-field
                v-model="targetFile"
                :rules="[rules.required, rules.counter, rules.special]"
                class="mb-3"
                counter="30"
                maxlength="30"
                dense
                label="Target File"
                hide-details="auto"
                @change="clearErrors()"
              />
              <v-col>
                <small
                  v-if="addError !== ''"
                  class="mb-5"
                  style="color: red"
                >
                  {{ addError }}
                </small>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  @click="validateAddTargetData('a')"
                >
                  add
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row no-gutters>
            <v-col
              sm="5"
              md="5"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
            <v-col
              class="text-right pr-10"
              cols="4"
              md="4"
            >
              <v-btn
                class="mt-3"
                color="primary"
                :disabled="selected.length === 0"
                @click="updateTargetData('u')"
              >
                Update
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              class="pr-6"
              sm="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            item-key="importCode"
            class="mt-10"
            :headers="targetDataHeaders"
            :items="matchedResults"
            :search="search"
            :loading="resultsLoading"
            single-select
            show-select
            checkbox-color="blue"
          >
            <template v-slot:[`item.data-table-select`]="{isSelected,select}">
              <v-simple-checkbox
                v-ripple
                color="primary"
                :value="isSelected"
                @input="select($event)"
              />
            </template>
            <template v-slot:[`item.targetFile`]="{ item }">
              <div>
                {{ item.importCode }}
              </div>
            </template>
            <template v-slot:[`item.createDate`]="{ item }">
              <div>
                <span>{{ item.createDate | displayDateFormat }}</span>
              </div>
            </template>
            <template v-slot:[`item.matchOptions`]="{ item }">
              <v-row>
                <div
                  v-for="match in item.matched"
                  :key="match.name"
                >
                  <v-chip
                    v-show="match.value !== false"
                    class="ml-1 mr-1"
                    :color="match.color"
                  >
                    {{ match.name }}
                  </v-chip>
                </div>
              </v-row>
            </template>
            <template v-slot:[`item.ama`]="{ item }">
              <div>
                <span>{{ item.amaRecords }}</span>
              </div>
            </template>
            <template v-slot:[`item.apn`]="{ item }">
              <div>
                <span>{{ item.apnRecords }}</span>
              </div>
            </template>
            <template v-slot:[`item.aapa`]="{ item }">
              <div>
                <span>{{ item.aapaRecords }}</span>
              </div>
            </template>
            <template v-slot:[`item.report`]="{ item }">
              <v-row no-gutters>
                <v-col>
                  <v-tooltip
                    v-if="isMMSRole && (item.amaRecords !== 0 || item.apnRecords !== 0 || item.aapaRecords !== 0)"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="reportLoading"
                        color="success"
                        disabled
                        v-on="on"
                      >
                        mdi-progress-download
                      </v-icon>
                      <v-icon
                        v-else
                        :value="getSelectedId()"
                        class="mx-1"
                        color="success"
                        :disabled="selectedId !== item.id"
                        @click="getReport('md')"
                        @click.native.stop
                        v-on="on"
                      >
                        mdi-bullseye-arrow
                      </v-icon>
                    </template>
                    <span>Med E-Mail Report</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tooltip
                    v-if="isMMSRole && (item.amaRecords !== 0 || item.apnRecords !== 0 || item.aapaRecords !== 0) && emailDownloadResults.field1 === 'A'"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :value="selectedId"
                        :disabled="selectedId !== item.id"
                        class="mx-1"
                        color="success"
                        @click="getReport('an')"
                        @click.native.stop
                        v-on="on"
                      >
                        mdi-calendar-month-outline
                      </v-icon>
                    </template>
                    <span>Annual Use Report</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isMMSRole && (item.amaRecords !== 0 || item.apnRecords !== 0 || item.aapaRecords !== 0) && emailDownloadResults.field1 === 'E'"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        :value="selectedId"
                        :disabled="selectedId !== item.id"
                        class="mx-1"
                        color="success"
                        @click="getReport('el')"
                        @click.native.stop
                        v-on="on"
                      >
                        mdi-email-multiple-outline
                      </v-icon>
                    </template>
                    <span>Email List Order Report</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'TargetData',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 1 || 'Min 1 characters',
        special: value => {
          const pattern = /^[a-zA-Z0-9 _-]*$/
          return pattern.test(value) || 'No special characters allowed.'
        }
      },
      targetFile: '',
      search: '',
      selected: [],
      selectedId: '',
      addError: '',
      isSelected: false,
      selectedItemCode: '',
      valid: false,
      brandName: '',
      results: [],
      matchedResults: [],
      emailDownloadResults: {},
      addResults: '',
      codeCountResults: '',
      resultsLoading: false,
      reportLoading: false,
      targetDataHeaders: [
        {
          text: 'Target File',
          align: 'start',
          sortable: true,
          class: 'title',
          value: 'importCode'
        },
        { text: 'Last Modified', value: 'createDate', sortable: true, class: 'title' },
        { text: 'Match Options', value: 'matchOptions', class: 'title', align: 'start' },
        { text: 'AMA', value: 'ama', sortable: false, class: 'title', align: 'center' },
        { text: 'APN', value: 'apn', sortable: false, class: 'title', align: 'center' },
        { text: 'AAPA', value: 'aapa', sortable: false, class: 'title', align: 'center' },
        { value: 'report', sortable: false, class: 'title', align: 'center' }
      ]
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  watch: {

  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.loadTargetData()
      }
    })
  },

  created () {
    this.loadTargetData()
    this.loadDownloadFileResponse()
  },

  methods: {
    clearErrors () {
      this.addError = ''
    },
    getSelectedId () {
      if (this.selected.length !== 0) {
        for (var i = 0; this.selected.length; i++) {
          this.selectedId = this.selected[i].id
          break
        }
      }
      return this.selectedId
    },
    getReport (value) {
      this.reportLoading = true
      for (var i = 0; this.selected.length; i++) {
        this.selectedId = this.selected[i].id
        break
      }
      if (value === 'md') {
        const link = document.createElement('a')
        link.href = this.emailDownloadResults.hypertextNew + this.selectedId
        this.reportLoading = false
        link.click()
      }
      if (value === 'an') {
        const link = document.createElement('a')
        link.href = this.emailDownloadResults.hypertextNew2 + this.selectedId
        link.click()
        this.reportLoading = false
      }
      if (value === 'el') {
        const link = document.createElement('a')
        link.href = this.emailDownloadResults.hypertextNew3 + this.selectedId
        link.click()
        this.reportLoading = false
      }
    },
    async loadTargetData () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getTargetData(_this.$store.getters['simulatedCustomerNowwId']())
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.matchedResults = _this.results.map(item => {
              return {
                ...item,
                matched: [{ name: 'ME', value: item.match10, color: '#8E24AA' },
                  { name: 'Mixed ME', value: item.matchMixed, color: '#2b93c1' },
                  { name: 'Name/Address', value: item.matchName, color: '#ff7235' },
                  { name: 'NPI', value: item.matchNPI, color: '#4bb2b4' }]
              }
            })
            _this.resultsLoading = false
          }
        })
    },
    async loadDownloadFileResponse () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getTDEmailDownloadCheck(_this.$store.getters['simulatedCustomerNowwId']())
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.emailDownloadResults = resp
            _this.resultsLoading = false
          }
        })
    },
    async validateAddTargetData (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.targetFile
      _this.resultsLoading = true
      await nowwService.targetDataCodeCount({
        customerId: customerId,
        supressionCode: _this.supressionCode
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else if (value === 'a' && resp !== 0) {
            _this.addError = 'There is already a target file with the code you entered.'
            _this.resultsLoading = false
          } else {
            _this.codeCountResults = resp
            _this.resultsLoading = false
            _this.addTargetData(value)
          }
        })
    },
    async addTargetData (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.targetFile
      _this.resultsLoading = true
      await nowwService.addTargetData({
        customerId: customerId,
        supressionCode: _this.supressionCode
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.addResults = resp
            _this.resultsLoading = false
            _this.$router.push({ path: '/targetDataImport',
              query: {
                cid: customerId,
                sc: _this.supressionCode,
                sid: _this.addResults }
            })
          }
        })
    },
    async updateTargetData (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      for (var i = 0; _this.selected.length; i++) {
        if (_this.selected[i].importCode !== undefined) {
          _this.selectedItemCode = _this.selected[i].importCode
          _this.selectedId = _this.selected[i].id
          break
        }
      }
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.targetFile
      _this.resultsLoading = true
      await nowwService.updateTargetData({
        customerId: customerId,
        supressionCode: _this.supressionCode
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else if (value === 'a' && resp !== 0) {
            _this.$store.commit('setGlobalSnackbar', {
              message: 'There is already a target file with the code you entered.',
              color: 'error'
            })
            _this.resultsLoading = false
          } else {
            _this.addResults = resp
            _this.resultsLoading = false
            _this.$router.push({ path: '/targetDataImport',
              query: {
                cid: customerId,
                sc: _this.supressionCode,
                sid: _this.selectedId }
            })
          }
        })
    }
  }

}
</script>
